import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Image from "next/image";
export const Img = ({nolazy, visible, srcSm, src, position, objectFit, height, width, banner, alt, left, margin, right, top, bottom}) => {

    const DefaultBanner = '/images/static/blurbg.svg';
    const [deviceWidth, setDeviceWidth] = useState(0);

    // set device width
    useEffect(() => {
        function HandleWidth() {
            setDeviceWidth(window.innerWidth)
        }

        window.addEventListener('resize', HandleWidth)
        HandleWidth()
        return () => {
            window.removeEventListener('resize', HandleWidth)
        };
    });
    return (
        <StyledImg className='global-image' srcSm={srcSm} objectFit={objectFit} margin={margin} position={position} left={left}
                   right={right} top={top}
                   bottom={bottom} height={height} width={width}>

                    <Image quality={100} priority blurDataURL={`${DefaultBanner}`}
                           alt={alt || 'Shanta Securities Limited'}  objectFit={'cover'} layout={'fill'}
                           src={src ? deviceWidth > 600 ? src : srcSm ? srcSm : src : DefaultBanner} // use normal <img> attributes as props
                    />

        </StyledImg>

    );
};

const StyledImg = styled.div`
    position: ${props => props.position || 'absolute'};
    height: ${props => props.height || '100%'};
    width: ${props => props.width || '100%'};
    top: ${props => props.top || 0};
    left: ${props => props.left || 0};
    bottom: ${props => props.bottom || 0};
    right: ${props => props.right || 0};
    margin: ${props => props.margin || 0};

    img {
        width: 100%;
        height: 100%;
        object-fit: ${props => props.objectFit || 'cover'};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
`;